import React, { useState,useEffect } from "react";
import { IoMdPerson } from "react-icons/io";
import { TiMessages } from "react-icons/ti";
import { useNavigate } from "react-router";
import { LATEST_NEWS } from "../../apiConst";
import { postRequest } from "../interceptor/interceptor";

const BlogSection = () => {
  useEffect(() => {
    getNewsData();
  }, []);
  const [blogData,setBlogData]=useState([
    // {
    //   id: 1,
    //   image: require("../../images/blog1.jpg"),
    //   date: "5 Dec",
    //   author: "admin",
    //   comments: 5,
    //   title: "Efficiency, Innovation, and IT Expertise",
    //   description:
    //     "Combining efficiency, innovation, and IT expertise to deliver transformative solutions that drive business growth. Our focus is on creating seamless, user-centric experiences that keep your goals at the forefront...",
    //   link: "#",
    // },
    // {
    //   id: 2,
    //   image: require("../../images/blog2.jpg"),
    //   date: "5 Dec",
    //   author: "admin",
    //   comments: 5,
    //   title: "Accelerating Your Digital Transformation",
    //   description:
    //     "Empowering businesses to embrace change and innovation with tailored digital solutions. We streamline your journey to success, ensuring clarity, efficiency, and measurable impact in every step of your digital...",
    //   link: "#",
    // },
    // {
    //   id: 3,
    //   image: require("../../images/blog3.jpg"),
    //   date: "5 Dec",
    //   author: "admin",
    //   comments: 5,
    //   title: "Transforming Business for the Future",
    //   description:
    //     "Leveraging advanced digital strategies to propel your business forward. Our solutions are designed to optimize performance, enhance customer experiences, and drive long-term success in the ever-evolving digital world.",
    //   link: "#",
    // },

  ])
  const navigate=useNavigate()
  const handleViewMore = (blog) => {
    navigate('/viewMore', { state: { blog } });
  };

  

  const getNewsData = () => {
   
    postRequest(`${LATEST_NEWS}`)
      .then((res) => {
        console.log('res',res)
        if (res) {
          setBlogData(res.data.data);
        }
      })
      .catch(() => {
        setBlogData([])
        
      });
  };

  return (
    <section className="bg-blue-50">
      <div className="container mx-auto">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h5 className="text-blue-600 text-sm font-semibold tracking-wide uppercase mb-3">
            Latest News
          </h5>
          <h2 className="text-gray-900 text-4xl font-bold">
            Unlocking Your Business's <br className="hidden lg:block" /> Digital
            Potential
          </h2>
        </div>
        {/* Blog Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 lg:px-28">
          {blogData.map((blog) => (
            <div key={blog.id} className="rounded-lg overflow-hidden">
              <div className="relative">
                <img
                  src={blog.image}
                  alt={`Blog ${blog.id}`}
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-3 right-3 bg-blue-600 text-white px-4 py-1 rounded text-sm font-medium">
                  {blog.date}
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-600 mb-4">
                  <span className="flex items-center mr-4 text-xs">
                    <IoMdPerson className="mr-1 text-blue-600" />
                    By {blog.author}
                  </span>
                  {/* <span className="flex items-center text-xs">
                    <TiMessages className="mr-1 text-blue-600" />
                    Comments ({blog.comments})
                  </span> */}
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-3">
                  {blog.title}
                </h3>
                <p className="text-sm text-gray-700 mb-4">{blog.description}</p>
                <button
                  // href={blog.link}
                  onClick={()=>handleViewMore(blog)}
                  className="text-blue-600 font-medium flex items-center"
                >
                  Read More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 ml-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 10H16a1 1 0 010 2H3.172l3.536 3.536a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L3.172 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
