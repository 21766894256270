import React, { useState, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import InputField from "../../Common/InputField";
import { contactFields } from "../../fields/contactFields";
import { toast } from "react-toastify";
import { BiSolidMessageDetail } from "react-icons/bi";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import axios from "axios";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

//   const handleCancel = () => {
//     reset();
//   };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = {
        firstName: data.firstName,
        lastName: data.lastName,
        contact: data.contact,
        email: data.email,
        message: data.message,
        domain:window.location.hostname
      };
      console.log("formData", formData);

      const response = await axios.post(`https://api.itpros.tech/oj/contacts/add`, formData);
      console.log("response", response);

      if (response.data.success && response.data.message) {
        toast.success(response.data.message, {
          autoClose: 800,
          onClose: () => {
            setIsLoading(false);
            reset();
          },
        });
      } else {
        toast.error(response.error, {
          autoClose: 700,
          onClose: () => {
            setIsLoading(false);
          },
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        autoClose: 500,
        onClose: () => {
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <>
    
      <div className="bg-blue-50 flex flex-row p-5 lg:px-28 lg:py-28 justify-center">
        <div className="max-w-full mx-auto rounded-3xl border border-gray-600 flex flex-row ">
          <div className="lg:w-1/2 p-8 hidden lg:block rounded-tl-xl rounded-bl-xl">
          <h5 className="text-blue-700 text-sm font-semibold tracking-wide uppercase mb-3">
          Get In Touch
          </h5>
            <h2 className="text-3xl font-bold text-center leading-[58px] text-slate-950 max-w-[824px] max-md:max-w-full max-md:text-4xl max-md:leading-[54px]">
              Connect with & ignite the conversation!
            </h2>
            
            <ul className="space-y-6 mt-4">
              {/* <li className="flex items-center">
                <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-200 text-blue-700 mr-4">
                <BiSolidMessageDetail size={'23'} className="text-primary-700" />
                </div>
                <div>
                  <p className="text-gray-900 font-medium">Chat with us.</p>
                  <p className="text-gray-500 text-sm">
                    Monday - Friday, 9am-5pm
                  </p>
                </div>
              </li> */}
              <li className="flex items-center">
                <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-200 text-white mr-4">
                <MdEmail size={'23'} className="text-primary-700"/>
                </div>
                <div>
                  <p className="text-gray-900 font-medium">Email with us.</p>
                  <a
                href={`mailto:${"contact@itpros.tech"}`}
                className="text-gray-800"
              >
                <span className="hover:text-blue-700">
                  contact@itpros.tech
                </span>
              </a>
                </div>
              </li>
              {/* <li className="flex items-center">
                <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gray-200 text-white mr-4">
                <FaPhone size={'23'} className="text-primary-700"/>
                </div>
                <div>
                  <p className="text-gray-900 font-medium">Give us a call.</p>
                  <p className="text-gray-500 text-sm">+ 01 234 567 88</p>
                  <p className="text-gray-500 text-sm">+ 01 234 567 89</p>
                </div>
              </li> */}
            </ul>
          </div>
          <div className="w-full lg:w-1/2 p-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">
              Fill up the form
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {contactFields.map((field) => {
                  const {
                    fieldTitle,
                    fieldName,
                    fieldType,
                    fieldMaxLength,
                    validationObj,
                    disabled,
                  } = field;
                  return (
                    <div
                      key={fieldName}
                      className={`${
                        fieldName === "message"
                          ? "col-span-1 sm:col-span-2"
                          : ""
                      }`}
                    >
                      <label className="block text-gray-800 font-semibold mb-2">
                        {fieldTitle}
                      </label>
                      {fieldName === "contact" ? (
                        <>
                          <PhoneInputWithCountry
                            flags={flags}
                            className="shadow appearance-none border border-gray-800 rounded-xl w-full px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 disabled:bg-gray-200"
                            defaultCountry="US"
                            name={fieldName}
                            control={control}
                            placeholder={fieldTitle}
                            rules={{
                              validate: (value) =>
                                (value && isPossiblePhoneNumber(value)) ||
                                "Enter a valid phone number",
                            }}
                          />
                          {errors.contact && (
                            <p className="text-red-500 pt-2 text-xs italic">
                              {errors.contact.message}
                            </p>
                          )}
                        </>
                      ) : (
                        <InputField
                          fieldName={fieldName}
                          validationObj={validationObj}
                          register={register}
                          maxLength={fieldMaxLength}
                          errors={errors}
                          fieldType={fieldType}
                          disabled={disabled}
                          placeholder={fieldTitle}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="flex w-full mt-6">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="bg-primary-900 text-white px-6 py-3 rounded-full w-full font-medium text-sm uppercase hover:bg-blue-600 transition duration-150 ease-in-out"
                >
                  {isLoading ? "Loading..." : "Get In Touch"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
